import { HStack, VStack, Box, Text } from "@chakra-ui/layout";
import { FormControl, Select } from "@chakra-ui/react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";

const BookFilterSelect = (props) => {
  const options = props.options.map((option) => {
    return <option value={option}>{option}</option>;
  });
  const setBookFilterValue = (event) => {
    console.log(event.target.value);
    props.onSelect(event.target.value);
  };
  return (
    <FormControl id="bookFilter" maxW="44">
      <Select value={props.value} onChange={setBookFilterValue}>
        {options}
      </Select>
    </FormControl>
  );
};

const BookList = (props) => {
  const books =
    props.filter === 0 || props.filter === "alle Bücher"
      ? props.books
      : props.books.filter((book) =>
          book.frontmatter.tags.includes(props.filter)
        );

  return books.map((book) => {
    const gatsbyCover = getImage(book.frontmatter.cover);
    return (
      <Box as={Link} to={book.fields.slug} position="relative">
        <Box
          as={GatsbyImage}
          image={gatsbyCover}
          alt={book.frontmatter.title}
          my="4"
          mr="7"
          ml="1"
          width="156px"
          borderRadius="2"
          shadow="1px 1px 4px 1px rgba(0,0,50,0.1)"
          transition="box-shadow 0.5s"
          _hover={{
            shadow: "2px 4px 24px 2px rgba(0,0,50,0.2)",
          }}
        ></Box>
        <Box
          position="absolute"
          zIndex="4"
          backgroundColor="white"
          opacity="0.3"
          width="1px"
          height="244"
          left="7px"
          top="16px"
          shadow="1px 0px 2px 0px rgba(0,0,0,0.3)"
        ></Box>{" "}
        {book.frontmatter.readingSample && (
          <Text
            backgroundColor="red.600"
            color="white"
            fontSize="sm"
            position="absolute"
            bottom="12"
            right="3"
            pb="0"
            px="4"
            fontWeight="700"
            _after={{
              w: "2",
              content: '" "',
              h: "2",
              backgroundColor: "transparent",
              position: "absolute",
              top: "7",
              right: "0",
              borderRight: "8px inset transparent",
              borderTop: "8px solid var(--chakra-colors-red-900)",
              borderBottom: "8px inset transparent",
              borderLeft: "8px solid var(--chakra-colors-red-900)",
            }}
          >
            Leseprobe
          </Text>
        )}
      </Box>
    );
  });
};

const Books = (props) => {
  const [bookFilter, setbookFilter] = useState("alle Bücher");

  const options = [
    "alle Bücher",
    ...new Set(props.books.map((book) => book.frontmatter.tags).flat()),
  ];

  function handleFilterChange(newFilter) {
    setbookFilter(newFilter);
  }

  return (
    <VStack pt="4" alignItems="start" width="100%">
      <HStack width="100%" justifyContent="space-between">
        <h2>{props.headline}</h2>
        {props.showFilter && (
          <BookFilterSelect
            options={options}
            onSelect={handleFilterChange}
            value={bookFilter}
          ></BookFilterSelect>
        )}
      </HStack>
      <HStack
        spacing="0"
        overflowY={props.slider ? "scroll" : "initial"}
        wrap={props.slider ? "nowrap" : "wrap"}
        maxWidth="100%"
      >
        <BookList books={props.books} filter={bookFilter} />
      </HStack>
    </VStack>
  );
};

export default Books;
